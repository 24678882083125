import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const NeuigkeitenHoerl = () => {
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Freie Mitarbeit Ausstellungsvermittlung"
        description="In freiberuflicher Mitarbeit können Sie sich in die Literatur- und Ausstellungsvermittlung unseres Museums mit einbringen."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Mitarbeiten",
                link: "/mitarbeiten",
              },
              {
                title: "Ausstellungsvermittlung",
                link: "/mitarbeiten/ausstellungsvermittlung",
              },
            ]}
          />
          <PageTitle>Freie Mitarbeit Ausstellungsvermittlung</PageTitle>

          <Paragraph dropcap={true}>
            Mit den Führungs- und Workshop-Angeboten zur Dauerausstellung und
            den wechselnden Sonderausstellungen öffnet sich unser Museum einem
            breiten Zielpublikum: Von allgemeinen Rundgängen bis hin zu
            Angeboten mit spezifischen Themenschwerpunkten bieten wir ein
            vielfäliges Vermittlungsprogramm für Privatgruppen, Touristen,
            Schulklassen aller Schularten, Studierende sowie Menschen mit und
            ohne Beeinträchtigungen. In freiberuflicher Mitarbeit können Sie
            sich in die Literatur- und Ausstellungsvermittlung unseres Museums
            mit einbringen.
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Die Aufgaben umfassen im Wesentlichen:
          </Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Einarbeitung in die Konzeption und Inhalte der Dauerausstellung im
              Hölderlinturm
            </Bullet>
            <Bullet>
              Einarbeitung in ausgewählte Führungs- und/oder Workshop-Programme
            </Bullet>
            <Bullet>
              Erstellen eigener Führungen und/oder Workshops auf der Grundlage
              der vorliegenden Konzepte und Materialien
            </Bullet>
            <Bullet>
              Zielgruppenorientierte Vermittlung der Ausstellungsinhalte an den
              Objekten der Ausstellung
            </Bullet>
          </BulletList>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Ihre Qualifikation:
          </Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Interesse am Leben und Werk Friedrich Hölderlins sowie der
              deutschen Literatur im Allgemeinen{" "}
            </Bullet>
            <Bullet>Kompetenzen in der didaktischen Vermittlung </Bullet>
            <Bullet>
              Erfahrungen im Bereich der Ausstellungs- und/oder
              Literaturvermittlung
            </Bullet>
            <Bullet>
              Kommunikationsfähigkeit und Offenheit gegenüber verschiedenen
              Zielgruppe
            </Bullet>
          </BulletList>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Wir erwarten:
          </Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Begeisterungsfähigkeit, Aufgeschlossenheit und Eigenengagement
            </Bullet>
            <Bullet>selbstständige Arbeitsweise </Bullet>
            <Bullet>Pünktlichkeit und Zuverlässigkeit</Bullet>
            <Bullet>Fremdsprachenkenntnisse sind von Vorteil </Bullet>
          </BulletList>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Wir bieten:
          </Heading>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              ein interessantes und innovatives Ausstellungskonzept
            </Bullet>
            <Bullet>
              ein vielschichtiges Vermittlungs- und Veranstaltungsprogramm{" "}
            </Bullet>
            <Bullet>eine abwechslungsreiche und eigenständige Tätigkeit</Bullet>
            <Bullet>
              regelmäßigen Austausch mit anderen freiberuflichen
              Vermittler*innen
            </Bullet>
          </BulletList>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Interesse?
          </Heading>
          <Paragraph>
            Schreiben Sie uns gerne eine Mail mit einem Motivationsschreiben und
            Lebenslauf an:
          </Paragraph>
          <Paragraph>
            <TextLink href="mailto:hoelderlinturm@tuebingen.de">
              hoelderlinturm@tuebingen.de
            </TextLink>
          </Paragraph>
          <Paragraph>
            Ansprechpartner: Florian Mittelhammer (Leitender Kurator)
          </Paragraph>
          <Paragraph>
            Bewerbungen von Studierenden sind ausdrücklich erwünscht!
          </Paragraph>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoerl
